export default [
  {
    path: '/dashboard',
    name: 'dashboard-partner',
    component: () => import('@/views/Partner/dashboard/dashboard.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/table',
    name: 'table',
    component: () => import('@/views/Partner/table.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Deposit',
    name: 'deposit',
    component: () => import('@/views/Partner/deposit/deposit.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Deposit-Resend',
    name: 'deposit-resend',
    component: () => import('@/views/Partner/deposit/deposit_resend.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Deposit-FromAdmin',
    name: 'deposit-fromadmin',
    component: () => import('@/views/Partner/deposit/deposit_fromAdmin.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Deposit-Admin',
    name: 'deposit-admin',
    component: () => import('@/views/Partner/deposit/deposit_Admin.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Withdraw',
    name: 'withdraw',
    component: () => import('@/views/Partner/withdraw/withdraw.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/movecredit',
    name: 'movecredit',
    component: () => import('@/views/Partner/movecredit/index.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Member',
    name: 'member',
    component: () => import('@/views/Partner/member/member.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/loguser',
    name: 'loguser',
    component: () => import('@/views/Partner/loguser/index.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/HistoryReport',
    name: 'HistoryReport',
    component: () => import('@/views/Partner/CRM/HistoryReport.vue'),
    meta: {
      resource: 'Crm',
      action: 'read',
    },
  },
  {
    path: '/DailyPlayTotal',
    name: 'DailyPlayTotal',
    component: () => import('@/views/Partner/CRM/DailyPlayTotal.vue'),
    meta: {
      resource: 'Crm',
      action: 'read',
    },
  },
  {
    path: '/Member-add',
    name: 'member-add',
    component: () => import('@/views/Partner/member/add.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/event-bonus',
    name: 'event-bonus',
    component: () => import('@/views/Partner/event-bonus/index-bonus.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/event/history',
    name: 'event-history',
    component: () => import('@/views/Partner/event-bonus/history.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/event/point',
    name: 'event-point',
    component: () => import('@/views/Partner/event-bonus/event-point.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/event/history/redeem',
    name: 'history-redeem',
    component: () => import('@/views/Partner/event-bonus/history-redeem.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/dashboard-detail',
    name: 'dashboard-detail',
    component: () => import('@/views/Partner/dashboard-detail/dash_detail.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/deposit-withdraw',
    name: 'deposit-withdraw',
    component: () => import('@/views/Partner/dashboard-detail/dpo_with.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/invite-friend',
    name: 'invite-friend',
    component: () => import('@/views/Partner/invite-friend/view.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/invite-friend',
    name: 'invite-friends',
    component: () => import('@/views/Partner/invite-friend/view_marketing.vue'),
    meta: {
      resource: 'marketing',
      action: 'read',
    },
  },
  {
    path: '/sms',
    name: 'send-sms',
    component: () => import('@/views/Partner/sendSMS/sms.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/invite-detail',
    name: 'invite-detail',
    component: () => import('@/views/Partner/invite-friend/detail.vue'),
    meta: {
      layout: 'full',
      resource: 'zean',
      action: 'read',
    },
  },
  {
    path: '/invite-detail/:id/:username',
    name: 'invite-detail-byadmin',
    component: () => import('@/views/Partner/invite-friend/detail.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/invite-detail-all/:id',
    name: 'invite-detail-all-byadmin',
    component: () => import('@/views/Partner/invite-friend/detail_all.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/zean-invite-detail-all/:id',
    name: 'invite-detail-all',
    component: () => import('@/views/Partner/invite-friend/detail_all.vue'),
    meta: {
      layout: 'full',
      resource: 'zean',
      action: 'read',
    },
  },
  {
    path: '/zean-invite-add',
    name: 'zean-invite-add',
    component: () => import('@/views/Partner/invite-friend/add_by_zian.vue'),
    meta: {
      layout: 'full',
      resource: 'zean',
      action: 'read',
    },
  },
  {
    path: '/lucky-wheel',
    name: 'lucky-wheel',
    component: () => import('@/views/Partner/wheel/wheel.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/wheel/setting',
    name: 'wheel-setting',
    component: () => import('@/views/Partner/wheel/setting.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/wheel/history',
    name: 'wheel-history',
    component: () => import('@/views/Partner/wheel/history.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/dailylogin/setting',
    name: 'dailylogin-setting',
    component: () => import('@/views/Partner/dailylogin/setting.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/user-profile',
    name: 'user-profile',
    component: () => import('@/views/Partner/invite-friend/user_profile.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Deposit-Withdraw/:id',
    name: 'Deposit-Withdraw',
    component: () => import('@/views/Partner/history/deposit_withdraw.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/history/log',
    name: 'history',
    component: () => import('@/views/Partner/history/history.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/history/UserEditlog',
    name: 'history-UserEditlog',
    component: () => import('@/views/Partner/history/history_EditUser.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Edit-Data-zean/:id',
    name: 'Deposit-Withdraw-zean',
    component: () => import('@/views/Partner/invite-friend/EditData.vue'),
    meta: {
      layout: 'full',
      resource: 'zean',
      action: 'read',
    },
  },
  {
    path: '/user-detail-zean/:id',
    name: 'user-detail-zean',
    component: () => import('@/views/Partner/invite-friend/userdetail.vue'),
    meta: {
      layout: 'full',
      resource: 'zean',
      action: 'read',
    },
  },
  {
    path: '/History-Play/:id',
    name: 'History-Play',
    component: () => import('@/views/Partner/history/play.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/sms-deposit',
    name: 'sms-deposit',
    component: () => import('@/views/Partner/sms/smsDeposit.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/sms-withdraw',
    name: 'sms-withdraw',
    component: () => import('@/views/Partner/sms/smsWithdraw.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/sms-all',
    name: 'sms-all',
    component: () => import('@/views/Partner/sms/smsAll.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/sms-otp',
    name: 'sms-otp',
    component: () => import('@/views/Partner/sms/otp.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/statement',
    name: 'statement',
    component: () => import('@/views/Partner/sms/statement.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/scb-connect',
    name: 'scb-connect',
    component: () => import('@/views/Partner/sms/scbConnect.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/kplus-gen',
    name: 'kplus-gen',
    component: () => import('@/views/Partner/sms/kplus-gen.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/kbank-Statement',
    name: 'kbank-statement',
    component: () => import('@/views/Partner/sms/kbankstatement.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/crypto-Statement',
    name: 'crypto-statement',
    component: () => import('@/views/Partner/sms/cryptostatement.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/kbank-live',
    name: 'kbank-live',
    component: () => import('@/views/Partner/sms/kbankLive.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/true-money',
    name: 'true-money',
    component: () => import('@/views/Partner/sms/trueMoney.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/waiting-dep',
    name: 'waiting-dep',
    component: () => import('@/views/Partner/sms/waitingDep.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/bank',
    name: 'bank',
    component: () => import('@/views/Partner/admin/bank.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/auto-withdraw-scb',
    name: 'auto-withdraw-scb',
    component: () => import('@/views/Partner/admin/auto-withdraw-scb.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/movebankcheck',
    name: 'movebankcheck',
    component: () => import('@/views/Partner/admin/movebankcheck.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/log-admin',
    name: 'log-admin',
    component: () => import('@/views/Partner/history/log_admin.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/log-withdraw',
    name: 'log-withdraw',
    component: () => import('@/views/Partner/history/log_withdraw.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/auto-withdraw-kbank',
    name: 'auto-withdraw-kbank',
    component: () => import('@/views/Partner/admin/auto-withdraw-kbank.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/promotion',
    name: 'promotion',
    component: () => import('@/views/Partner/admin/promotion.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/promotions',
    name: 'promotions',
    component: () => import('@/views/Partner/admin/promotionV2.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/channel',
    name: 'channel',
    component: () => import('@/views/Partner/admin/channel.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/admin-management',
    name: 'admin-management',
    component: () => import('@/views/Partner/admin/admin.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/admin-agent-management',
    name: 'admin-agent-management',
    component: () => import('@/views/Partner/admin/admin_agent.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import('@/views/Partner/admin/setting.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/agent-list',
    name: 'agent-list',
    component: () => import('@/views/Partner/admin/agent-list.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/manage-agent/:id',
    name: 'manage-agent',
    component: () => import('@/views/Partner/admin/manage-agent.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/sbobet-win-lose',
    name: 'report',
    component: () => import('@/views/Partner/sbobet/reports.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/sbobet-setting',
    name: 'sbobet-setting',
    component: () => import('@/views/Partner/sbobet/sbo.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/sbobet-table',
    name: 'sbobet-table',
    component: () => import('@/views/Partner/sbobet/table.vue'),
    meta: {
      resource: 'SBO',
      action: 'read',
    },
  },
  {
    path: '/scb-capcha/:ID',
    name: 'scb',
    component: () => import('@/views/Partner/scb.vue'),
    meta: {
      layout: 'full',
      // redirectIfLoggedIn: false,
      resource: 'Auth',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Partner/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/login-developer',
    name: 'auth-login-admin',
    component: () => import('@/views/Partner/Login-admin.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/login2',
    name: 'auth',
    component: () => import('@/views/Partner/login/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },

  // Setting games
  {
    path: '/customer-setting',
    name: 'customer-setting',
    component: () => import('@/views/Partner/setting/customer-setting.vue'),
    meta: {
      resource: 'theme',
      action: 'read',
    },
  },
  {
    path: '/games-setting',
    name: 'games-setting',
    component: () => import('@/views/Partner/setting/game-setting.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/system-setting',
    name: 'system-setting',
    component: () => import('@/views/Partner/setting/system-setting.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
]
